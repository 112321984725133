<template>
  <div class="container-fluid rounded bg-white py-4">
    <div class="sub-modal">
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche FdR :
          </span>
          <span style="font-size: 25px" class="font-weight-bold">{{
            FDR.name
          }}</span>
        </p>
        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              mode="show"
              @print="
                $router.push({
                  name: 'print-fdr',
                  params: {
                    id: $route.params.id,
                    slug: $route.params.slug,
                  },
                })
              "
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>

      <form id="add-page" @submit.prevent="updateFdr" class="row p-0 m-0 mb-3">
        <div class="row col-12 p-0 mx-auto">
          <div class="col-md-4 col-12 p-1">
            <div class="h-100 col-12 shdow">
              <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
                <p
                  class="h4 col-sm p-0 text-secondary my-auto mr-auto"
                  style="
                    margin-top: 7.656px !important;
                    margin-bottom: 7.656px !important;
                  "
                >
                  Informations générales
                </p>

                <button
                  class="btn btn-sm p-0"
                  :disabled="editMode && editing !== 'info'"
                  v-if="
                    (isManager || isAdmin || isSuper) &&
                    (!editMode || editing !== 'info')
                  "
                  @click="(editMode = !editMode), (editing = 'info')"
                >
                  <i
                    class="mdi mdi-file-document-edit-outline"
                    style="font-size: 35px; cursor: pointer"
                    :style="{
                      color:
                        editMode && editing !== 'info' ? 'gray' : ' #007bff',
                    }"
                  ></i>
                </button>
                <div
                  v-else-if="isManager || isAdmin || isSuper"
                  class="m-0 my-auto d-flex p-0"
                >
                  <b-button type="submit" size="sm" variant="secondary" pill
                    >Confirmer</b-button
                  >
                  <b-button
                    @click="cancelEdit"
                    style="background-color: #adb5bd; border-color: #adb5bd"
                    pill
                    size="sm"
                  >
                    Annuler</b-button
                  >
                </div>
              </div>
              <div class="col-12 p-0">
                <hr class="bg-secondary mb-3 mt-1" />
              </div>
              <div v-if="!editMode || editing !== 'info'" class="col-12 p-0">
                <div class="col-12 p-0">
                  <p>
                    <span>Nom : </span>
                    <span style="color: black" class="font-weight-bold">
                      {{ FDR.name }}
                    </span>
                  </p>
                  <p>
                    <span>Entité : </span>
                    <span style="color: black" class="font-weight-bold">{{
                      FDR.organisation && FDR.organisation.entite
                        ? FDR.organisation.entite.libelle
                        : "-"
                    }}</span>
                  </p>
                  <p>
                    <span>Organisation : </span>
                    <span style="color: black" class="font-weight-bold">
                      {{
                        FDR.organisation ? FDR.organisation.libelle : "-"
                      }}</span
                    >
                  </p>
                  <p>
                    <span>Budget : </span>
                    <span style="color: black" class="font-weight-bold">
                      {{
                        new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(FDR.budget || 0)
                      }}
                    </span>
                  </p>
                  <p>
                    <span>Patrimoine : </span>
                    <span style="color: black" class="font-weight-bold">
                      {{ FDR.patrimoine ? FDR.patrimoine.name : "-" }}</span
                    >
                  </p>
                  <p class="">
                    <span>Responsable finance : </span>

                    <span style="color: black" class="font-weight-bold">
                      {{
                        FDR.finance
                          ? FDR.finance.responsable
                            ? FDR.finance.responsable.firstname +
                              " " +
                              FDR.finance.responsable.lastname
                            : "-"
                          : "-"
                      }}
                    </span>
                  </p>

                  <p class="">
                    <span>Responsable : </span>
                    <!-- <TableResponsable class="ml-1" :responsable="FDR.responsable" /> -->
                    <span style="color: black" class="font-weight-bold">
                      {{
                        FDR.responsable
                          ? FDR.responsable.firstname +
                            " " +
                            FDR.responsable.lastname
                          : "-"
                      }}
                    </span>
                  </p>

                  <p>
                    <span>Type FdR : </span>
                    <span style="color: black" class="font-weight-bold"
                      >{{ FDR.type }}
                    </span>
                  </p>
                  <p>
                    <span>Statut : </span>
                    <span style="color: black" class="font-weight-bold"
                      >{{ FDR.status }}
                    </span>
                  </p>
                </div>
              </div>
              <div v-else class="col-12 p-0">
                <b-form-group
                  class="font-weight-bold"
                  label="Nom:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <b-form-input
                    v-model="newFdr.name"
                    type="text"
                    placeholder="Nom"
                    :class="{
                      'is-invalid':
                        $v.newFdr.name.$error && $v.newFdr.name.$dirty,
                    }"
                  ></b-form-input>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.name.$dirty"
                  >
                    {{
                      !$v.newFdr.name.required
                        ? "Champ obligatoire"
                        : !$v.newFdr.name.minLength
                        ? `Le champ doit contenir au moins ${$v.newFdr.name.$params.minLength.min} caractères.`
                        : ""
                    }}
                  </span>
                  <!--  -->
                </b-form-group>

                <b-form-group
                  class="font-weight-bold"
                  label="Entité:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                  >
                    <!-- :class="{
                      'is-invalid':
                        $v.newFdr.entite.$error && $v.newFdr.entite.$dirty,
                    }" -->
                    <v-select
                      :options="entityList"
                      label="text"
                      class="bg-transparent"
                      placeholder="Entité"
                      @input="entityChanged"
                      v-model="newFdr.entite"
                      :reduce="(entite) => entite.value"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <!-- <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.entite.$dirty"
                  >
                    {{ !$v.newFdr.entite.required ? "champ obligatoire" : "" }}
                  </span> -->
                  <!--  -->
                </b-form-group>
                <b-form-group
                  class="font-weight-bold"
                  label="Organisation:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                  >
                    <!-- :class="{
                      'is-invalid':
                        $v.newFdr.organisation.$error &&
                        $v.newFdr.organisation.$dirty,
                    }" -->
                    <v-select
                      label="text"
                      placeholder="Organisation"
                      v-model="newFdr.organisation"
                      :options="organisations"
                      :reduce="(organisation) => organisation.value"
                      class="bg-transparent"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <!-- <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.organisation.$dirty"
                  >
                    {{
                      !$v.newFdr.organisation.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span> -->
                  <!--  -->
                </b-form-group>
                <b-form-group
                  class="font-weight-bold"
                  label="Budget:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <CurrencyInput
                    placeholder="Budget"
                    v-model="newFdr.budget"
                    :value="newFdr.budget"
                    class="font-weight-normal"
                    :class="{
                      'is-invalid':
                        $v.newFdr.budget.$error && $v.newFdr.budget.$dirty,
                    }"
                    disabled
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.budget.$dirty"
                  >
                    {{
                      !$v.newFdr.budget.required
                        ? "Champ obligatoire"
                        : !$v.newFdr.budget.minValue
                        ? `Le budget doit étre positif.`
                        : ""
                    }}
                  </span>
                  <!--  -->
                </b-form-group>

                <b-form-group
                  class="font-weight-bold"
                  label="Date de création:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <date-picker
                    :value="datePickerFormat(newFdr.createdAt)"
                    class="col-12 p-0 font-weight-normal"
                    format="DD/MM/YYYY"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  class="font-weight-bold"
                  label="Patrimoine:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                  >
                    <!-- :class="{
                      'is-invalid':
                        $v.newFdr.patrimoine.$error &&
                        $v.newFdr.patrimoine.$dirty,
                    }" -->
                    <v-select
                      :options="patrimoineList"
                      label="text"
                      placeholder="Patrimoine"
                      v-model="newFdr.patrimoine"
                      class="bg-transparent"
                      :reduce="(patrimoine) => patrimoine.value"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <!-- <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.patrimoine.$dirty"
                  >
                    {{
                      !$v.newFdr.patrimoine.required ? "champ obligatoire" : ""
                    }}
                  </span> -->
                  <!--  -->
                </b-form-group>

                <b-form-group
                  class="font-weight-bold"
                  label="Responsable finance:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid':
                        $v.newFdr.finance.responsable.$error &&
                        $v.newFdr.finance.responsable.$dirty,
                    }"
                  >
                    <v-select
                      placeholder="Responsable finance"
                      label="text"
                      class="bg-transparent"
                      :options="responsables"
                      :reduce="(responsable) => responsable.value"
                      v-model="newFdr.finance.responsable"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.sponsor.$dirty"
                  >
                    {{ !$v.newFdr.sponsor.required ? "Champ obligatoire" : "" }}
                  </span>
                  <!--  -->
                </b-form-group>

                <b-form-group
                  class="font-weight-bold"
                  label="Responsable:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid':
                        $v.newFdr.responsable.$error &&
                        $v.newFdr.responsable.$dirty,
                    }"
                  >
                    <v-select
                      placeholder="Responsable"
                      v-model="newFdr.responsable"
                      label="text"
                      class="bg-transparent"
                      :options="responsables"
                      :reduce="(responsable) => responsable.value"
                      required
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>

                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.responsable.$dirty"
                  >
                    {{
                      !$v.newFdr.responsable.required ? "Champ obligatoire" : ""
                    }}
                  </span>
                  <!--  -->
                </b-form-group>

                <b-form-group
                  class="font-weight-bold"
                  label="Type FDR:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid':
                        $v.newFdr.type.$error && $v.newFdr.type.$dirty,
                    }"
                  >
                    <v-select
                      :options="fdrTypes"
                      class="bg-transparent"
                      placeholder="Type FDR"
                      v-model="newFdr.type"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.type.$dirty"
                  >
                    {{ !$v.newFdr.type.required ? "Champ obligatoire" : "" }}
                  </span>
                  <!--  -->
                </b-form-group>

                <b-form-group
                  class="font-weight-bold"
                  label="Statut:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid':
                        $v.newFdr.status.$error && $v.newFdr.status.$dirty,
                    }"
                  >
                    <v-select
                      v-model="newFdr.status"
                      placeholder="Statut"
                      class="bg-transparent"
                      :options="statusFdr"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.status.$dirty"
                  >
                    {{ !$v.newFdr.status.required ? "Champ obligatoire" : "" }}
                  </span>
                  <!--  -->
                </b-form-group>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12 p-1">
            <div class="h-100 col-12 shdow">
              <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
                <p
                  class="h4 text-secondary my-auto mr-auto"
                  style="
                    margin-top: 7.656px !important;
                    margin-bottom: 7.656px !important;
                  "
                >
                  Avancement
                </p>

                <button
                  class="btn btn-sm p-0"
                  :disabled="editMode && editing !== 'avancement'"
                  v-if="
                    (isManager || isAdmin || isSuper) &&
                    (!editMode || editing !== 'avancement')
                  "
                  @click="(editMode = true), (editing = 'avancement')"
                >
                  <i
                    class="mdi mdi-file-document-edit-outline"
                    style="font-size: 35px; cursor: pointer"
                    :style="{
                      color:
                        editMode && editing !== 'avancement'
                          ? 'gray'
                          : ' #007bff',
                    }"
                  ></i>
                </button>
                <div
                  v-else-if="isManager || isAdmin || isSuper"
                  class="m-0 d-flex p-0"
                >
                  <b-button type="submit" size="sm" variant="secondary" pill
                    >Confirmer</b-button
                  >
                  <b-button
                    @click="cancelEdit"
                    style="background-color: #adb5bd; border-color: #adb5bd"
                    pill
                    size="sm"
                  >
                    Annuler</b-button
                  >
                </div>
              </div>
              <div class="col-12 p-0">
                <hr class="bg-secondary mb-3 mt-1" />
              </div>
              <div
                v-if="!editMode || editing !== 'avancement'"
                class="col-12 p-0"
              >
                <div class="col-12 p-0">
                  <p>
                    <span>Date début : </span>
                    <span style="color: black" class="font-weight-bold">
                      {{
                        moment(FDR.startedAt, "DD/MM/YYYY").format("DD/MM/YYYY")
                      }}
                    </span>
                  </p>

                  <p>
                    <span>Date fin : </span>
                    <span style="color: black" class="font-weight-bold">
                      {{ FDR.endingAt }}
                    </span>
                  </p>

                  <p class="d-flex p-0 m-0">
                    <span> Avancement : </span>
                    <span style="color: black" class="ml-1 col-8 p-0 my-auto">
                      <b-progress
                        max="100"
                        show-progress
                        height="15px"
                        style="background-color: #cacccd"
                      >
                        <b-progress-bar
                          :value="FDR.avancement || 0"
                          :label="`${FDR.avancement || 0}%`"
                          :style="`background-color: ${
                            FDR.avancement > 20 && FDR.avancement <= 70
                              ? '#f5f245'
                              : FDR.avancement > 70
                              ? '#23d92c'
                              : '#f5ba45'
                          }`"
                        ></b-progress-bar>
                      </b-progress>
                    </span>
                  </p>
                </div>
              </div>
              <div v-else class="col-12 p-0">
                <b-form-group
                  class="font-weight-bold"
                  label="Date début:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <date-picker
                    class="col-12 p-0 font-weight-normal"
                    v-model="newFdr.startedAt"
                    placeholder="Date début"
                    format="DD/MM/YYYY"
                    :input-attr="{
                      class: {
                        'is-invalid':
                          $v.newFdr.startedAt.$error &&
                          $v.newFdr.startedAt.$dirty,
                        ' form-control': true,
                      },
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.startedAt.$dirty"
                  >
                    {{
                      !$v.newFdr.startedAt.required ? "Champ obligatoire" : ""
                    }}
                  </span>
                  <!--  -->
                </b-form-group>

                <b-form-group
                  class="font-weight-bold"
                  label="Date fin:"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <!-- <date-picker /> -->
                  <date-picker
                    class="col-12 p-0 font-weight-normal"
                    v-model="newFdr.endingAt"
                    placeholder="Date fin"
                    format="DD/MM/YYYY"
                    :input-attr="{
                      class: {
                        'is-invalid':
                          $v.newFdr.endingAt.$error &&
                          $v.newFdr.endingAt.$dirty,
                        ' form-control': true,
                      },
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newFdr.endingAt.$dirty"
                  >
                    {{
                      !$v.newFdr.endingAt.required
                        ? "Champ obligatoire"
                        : $v.newFdr.endingAt.required &&
                          $v.newFdr.endingAt.$error
                        ? "La date de fin ne doit pas être antérieur à la date de début"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12 p-1">
            <div class="h-100 col-12 shdow">
              <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
                <p
                  style="
                    margin-top: 7.656px !important;
                    margin-bottom: 7.656px !important;
                  "
                  class="h4 text-secondary my-auto mr-auto"
                >
                  Couvertures
                </p>

                <button
                  :disabled="editMode && editing !== 'couverture'"
                  v-if="
                    (isManager || isAdmin || isSuper) &&
                    (!editMode || editing !== 'couverture')
                  "
                  @click="(editMode = true), (editing = 'couverture')"
                  class="btn btn-sm p-0"
                >
                  <i
                    class="mdi mdi-file-document-edit-outline"
                    style="font-size: 35px; cursor: pointer"
                    :style="{
                      color:
                        editMode && editing !== 'couverture'
                          ? 'gray'
                          : ' #007bff',
                    }"
                  ></i>
                </button>
                <div
                  v-else-if="isManager || isAdmin || isSuper"
                  class="m-0 d-flex p-0"
                >
                  <b-button type="submit" size="sm" variant="secondary" pill
                    >Confirmer</b-button
                  >
                  <b-button
                    @click="cancelEdit"
                    style="background-color: #adb5bd; border-color: #adb5bd"
                    pill
                    size="sm"
                  >
                    Annuler</b-button
                  >
                </div>
              </div>
              <div class="col-12 p-0">
                <hr class="bg-secondary mb-3 mt-1" />
              </div>
              <div
                v-if="!editMode || editing !== 'couverture'"
                class="col-12 p-0 m-0 row"
              >
                <div
                  style="height: 50px; position: relative; overflow: visible"
                  class="shdow m-1"
                  v-for="(covert, index) in FDR.couvertures"
                  :key="index"
                >
                  <p>
                    <country-flag
                      :country="covert.countryCode"
                      size="small"
                    />{{ covert.country || "" }}
                  </p>
                </div>
                <div
                  class="text-center text-gray col-12 p-1"
                  v-if="!couvertures.length"
                >
                  Aucune couverture
                </div>
              </div>
              <div v-else class="col-12 p-0">
                <Places :testCouverture="sendingFunction" />

                <div class="row m-0 p-0">
                  <div
                    style="height: 50px; position: relative; overflow: visible"
                    class="shdow m-1"
                    v-for="(covert, index) in couvertures"
                    :key="index"
                  >
                    <p>
                      <country-flag
                        :country="covert.countryCode"
                        size="small"
                      />{{ covert.country || "" }}
                    </p>

                    <div style="position: absolute; top: -5px; right: -5px">
                      <span style="cursor: pointer"
                        ><i
                          @click="deleteCouverture(covert.id, index)"
                          class="mdi mdi-close-circle text-danger"
                        ></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr />
      <!-- {{ this.$store.state.tabs.activeTab }} -->
      <!-- {{ $route.current }} -->
      <b-tabs
        class="tab-solid tab-solid-primary mt-5 col-12 p-0 mx-auto"
        v-model="activeTab"
        @activate-tab="
          (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
        "
      >
        <b-tab
          title="Finance"
          v-if="
            ((isManager || isPo) &&
              FDR.responsable &&
              FDR.responsable.id === AuthenticatedUser.id) ||
            isAdmin ||
            isSuper ||
            isFinance
          "
          lazy
          class="mt-2"
        >
          <div class="row col-12 p-0 m-0">
            <div class="col-12 p-1">
              <!-- <div class="col-md-6 col-12 mb-2 px-1"> -->

              <div class="m-0 p-0 h-100 shdow">
                <div class="hdr py-2 text-white rounded mb-3">
                  Situation actuelle : {{ moment().format("DD/MM/YYYY") }}
                </div>
                <div class="p-4">
                  <div class="d-flex col-12 p-0">
                    <div class="col-md-6 col-12 pl-0">
                      <p>
                        <span>Budget total des initiatives : </span>
                        <span class="font-weight-bold">
                          {{
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(initiativeBudget || 0)
                          }}</span
                        >
                      </p>
                      <p>
                        <span>Budget FdR : </span>
                        <span class="font-weight-bold">
                          {{
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(parseFloat(FDR.budget))
                          }}</span
                        >
                      </p>
                      <p>
                        <span>Ecart budget : </span
                        ><span
                          class="font-weight-bold"
                          style="font-weight: bold"
                          :style="{
                            color:
                              FDR.budget - initiativeBudget <= 0
                                ? 'red'
                                : '#1dbf4d',
                          }"
                        >
                          <!-- :style="{
                            color:
                              FDR.finance && FDR.finance.ajustements
                                ? FDR.finance.ajustements.length > 1 &&
                                  parseFloat(
                                    FDR.finance.ajustements[
                                      FDR.finance.ajustements.length - 1
                                    ].montant
                                  ) -
                                    parseFloat(
                                      FDR.finance.ajustements[1].montant
                                    ) >=
                                    0
                                  ? 'red'
                                  : FDR.finance.ajustements.length === 2 &&
                                    parseFloat(
                                      FDR.finance.ajustements[1].montant
                                    ) >= 0
                                  ? 'red'
                                  : '#1dbf4d'
                                : '#1dbf4d',
                          }" -->
                          {{
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(ecartBudget)
                          }}
                        </span>
                      </p>
                      <!-- {{ FDR.finance ? FDR.finance.ajustements : [] }} -->
                      <!--  -->
                    </div>

                    <div class="col-md-6 col-12 pl-0">
                      <p>
                        <span>Dernier ajustement : </span>
                        <span
                          class="font-weight-bold"
                          v-if="
                            FDR.finance &&
                            FDR.finance.ajustements &&
                            FDR.finance.ajustements.length
                          "
                        >
                          {{ lastAdjustement }} par
                          {{
                            FDR.finance.ajustements.length > 1
                              ? FDR.finance.ajustements[
                                  FDR.finance.ajustements.length - 1
                                ].responsable
                                ? FDR.finance.ajustements[
                                    FDR.finance.ajustements.length - 1
                                  ].responsable.firstname +
                                  " " +
                                  FDR.finance.ajustements[
                                    FDR.finance.ajustements.length - 1
                                  ].responsable.lastname
                                : "-"
                              : FDR.finance.responsable
                              ? FDR.finance.responsable.firstname +
                                " " +
                                FDR.finance.responsable.lastname
                              : ""
                          }}</span
                        >
                        <span v-else> -</span>
                      </p>
                      <form @submit.prevent="addAjustement">
                        <div class="row m-0 p-0 align-items-center">
                          <span class="mr-2 col-3 p-0">Ajustement</span>

                          <CurrencyInput
                            v-model="ajustement.montant"
                            :value="ajustement.montant"
                            size="sm"
                            placeholder="Ajuster"
                            class="col-5 mr-2"
                            :class="{
                              'is-invalid':
                                $v.ajustement.montant.$error &&
                                $v.ajustement.montant.$dirty,
                            }"
                          />
                        </div>
                        <div>
                          <!-- ERROR MESSAGES -->
                          <span
                            style="font-size: x-small"
                            class="text-danger font-weight-normal"
                            v-if="$v.ajustement.montant.$dirty"
                          >
                            {{
                              !$v.ajustement.montant.required
                                ? "Champ obligatoire"
                                : !$v.ajustement.montant.minValue
                                ? `L'ajustement ne doit pas dépasser le budget de FdR .`
                                : ""
                            }}
                          </span>
                          <!--  -->
                        </div>
                        <div class="row m-0 mt-2 p-0 align-items-center">
                          <span class="mr-2 col-3 p-0">Commentaire</span>
                          <b-form-input
                            placeholder="Commentaire"
                            class="col-5 mr-2"
                            size="sm"
                            v-model="ajustement.commentaire"
                            required
                          ></b-form-input>
                        </div>
                        <div class="row m-0 mt-2 p-0 align-items-center">
                          <div class="d-flex p-0 col-8">
                            <b-button
                              size="sm"
                              type="submit"
                              class="bg-blue ml-auto"
                              :disabled="
                                !(
                                  (isPo &&
                                    INITIATIVE.responsable.id ===
                                      AuthenticatedUser.id) ||
                                  isAdmin ||
                                  isSuper
                                ) ||
                                ajustement.montant === 0 ||
                                ajustement.montant === ''
                              "
                              >Réviser</b-button
                            >
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <br />
                  <br />
                  <div>
                    <p class="d-flex">
                      <span class="mr-2 my-auto">
                        <span class="my-auto">Fichiers :</span>
                        <span
                          v-if="
                            !FDR.finance ||
                            !FDR.finance.fileFinances ||
                            !FDR.finance.fileFinances.length
                          "
                        >
                          -
                        </span>
                      </span>
                      <button
                        v-if="
                          ((isPo &&
                            FDR.responsable.id === AuthenticatedUser.id) ||
                            isAdmin ||
                            isSuper) &&
                          !editFinanceFile
                        "
                        @click="editFinanceFile = true"
                        class="btn btn-sm btn-info"
                      >
                        Modifier
                      </button>
                      <span
                        v-else-if="
                          (isPo &&
                            FDR.responsable.id === AuthenticatedUser.id) ||
                          isAdmin ||
                          isSuper
                        "
                        class="d-flex"
                      >
                        <button
                          @click="confirmFinanceFile"
                          variant="success"
                          class="btn btn-sm btn-success"
                        >
                          Confirmer
                        </button>
                        <button
                          @click="editFinanceFile = false"
                          class="btn btn-sm btn-danger"
                        >
                          Annuler
                        </button>
                      </span>
                    </p>

                    <FilePicker
                      v-if="editFinanceFile"
                      @click.prevent=""
                      class="font-weight-normal"
                      :isfinance="true"
                      @change="filePickerChanger"
                    />
                    <span style="color: black" class="font-weight-bold">
                      <b-list-group class="mt-3" v-if="FDR.finance">
                        <b-list-group-item
                          v-for="(livrable, index) in FDR.finance.fileFinances"
                          :key="index"
                          class="d-flex"
                          ><i
                            class="fa my-auto pr-1 fa-paperclip"
                            aria-hidden="true"
                          ></i>
                          <a target="_blank" :href="IMG_URL + livrable.fileUrl">
                            {{ livrable.filename }}</a
                          >
                          <i
                            v-if="!editFinanceFile"
                            @click="deleteLivrable(livrable)"
                            class="fa my-auto fa-times text-danger ml-auto"
                            aria-hidden="true"
                            style="cursor: pointer"
                          ></i>
                        </b-list-group-item>
                      </b-list-group>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="false" class="col-md-6 col-12 mb-2 px-1">
              <div class="m-0 p-0 h-100 shdow">
                <div class="hdr py-2 text-white rounded mb-3">Suivi conso:</div>

                <div class="p-2 d-flex">
                  <div
                    class="row col-md-6 p-0 m-0 align-items-center py-2 px-3"
                  >
                    <PieChart style="width: 100%" :options="pieOptions" />
                  </div>
                  <div
                    class="row col-md-6 p-0 m-0 align-items-center py-2 px-3"
                  >
                    <PieChart style="width: 100%" :options="pieConsoOptions" />
                  </div>
                </div>
                <div class="p-2">
                  <FollowConsomation />
                </div>
              </div>
            </div>
          </div>

          <div class="hdr py-2 text-white rounded-top mb-3">
            Historique des révisions
          </div>
          <RevisionHistorique :budget="FDR.budget" :finance="FDR.finance" />
        </b-tab>
        <b-tab
          v-if="isAdmin || isSuper || isManager"
          title="Enjeux stratégiques"
        >
          <StrategicChallenge :objectifs="FDR.objectifs" />
        </b-tab>
        <b-tab v-if="isAdmin || isSuper || isManager" title="Initiatives">
          <Initiatives
            :initiatives="FDR.initiatives"
            :responsables="responsables"
            :fdrName="newFdr.name"
          />
        </b-tab>
        <b-tab
          v-if="isAdmin || isSuper || isManager"
          title="Plan de transformation"
        >
          <TransformationPlan>
            <fiche-Applicative />
          </TransformationPlan>
        </b-tab>
        <b-tab
          v-if="isAdmin || isSuper || isManager"
          title="Comité et décision"
        >
          <CommitteeDecision
            @searchGuests="searchGuestsModal = !searchGuestsModal"
          />
        </b-tab>
      </b-tabs>
    </div>

    <!-- Search Guests -->
    <div to="modals-xyz-548" v-if="searchGuestsModal">
      <Modal @close="searchGuestsModal = !searchGuestsModal">
        <SearchGuests @close="searchGuestsModal = !searchGuestsModal" />
      </Modal>
    </div>
  </div>
</template>

<script>
import CommitteeDecision from "./components/CommitteeDecision.vue";
import StrategicChallenge from "./components/StrategicChallenge.vue";
import Initiatives from "./components/Initiatives.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import TransformationPlan from "./components/TransformationPlan.vue";
import FicheApplicative from "./components/FicheApplicative.vue";
import { mapGetters } from "vuex";
import PieChart from "@/components/charts/c3-chart/donut-c3.vue";
import RevisionHistorique from "./components/RevisionHistorique.vue";
import moment from "moment";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { required, minLength } from "vuelidate/lib/validators";
import Places from "@/components/custom/Places.vue";
import CountryFlag from "vue-country-flag";
import Swal from "sweetalert2";
import CurrencyInput from "@/components/custom/CurrencyInput";
import FollowConsomation from "./components/FollowConsomation";
import FilePicker from "@/components/custom/FilePicker.vue";
import { IMG_URL } from "@/helpers/services/api";

export default {
  name: "ShowFdr",
  components: {
    PieChart,
    CommitteeDecision,
    FicheApplicative,
    StrategicChallenge,
    Initiatives,
    ModalActions,
    TransformationPlan,
    RevisionHistorique,
    Places,
    CountryFlag,
    CurrencyInput,
    FollowConsomation,
    FilePicker,
  },
  data() {
    return {
      activeTab: this.$route.params.fromInitiative
        ? 2
        : this.$store.state.tabs.activeTab,
      couvertures: [],
      deletedCouverture: [],
      text: "",
      editing: "",

      pieConsoOptions: {
        data: {
          columns: [
            ["Ressources Internes", 5500],
            ["Externes", 2500],
            ["Achat matériel", 2000],
          ],
          type: "donut",
        },
        color: {
          pattern: ["#FFC000", "#072648", "#82D4BB"],
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        },
        bar: {
          width: {
            ratio: 0.7, // this makes bar width 50% of length between ticks
          },
        },
      },
      transformationInitiativeOptions: [{ value: null, text: "Toutes" }],
      transformationApplicationOptions: [{ value: null, text: "Applications" }],
      selectedTransformationInitiative: { text: "Toutes", value: null },
      selectedTransformationApplication: { text: "Application", value: null },
      searchGuestsModal: false,
      showAddInitiative: false,
      editMode: false,
      pieOptions: {
        data: {
          columns: [
            ["Consommé", 6000],
            ["Reste", 4000],
          ],
          type: "donut",
        },
        color: {
          pattern: ["#FFC000", "#072648"],
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        },
        bar: {
          width: {
            ratio: 0.7, // this makes bar width 50% of length between ticks
          },
        },
      },
      newFdr: {
        endingAt: "",
        startedAt: "",
        status: "",
        type: "",
        responsable: "",
        sponsor: "",
        patrimoine: "",
        organisation: "",
        entite: "",
        name: "",
        budget: 0,
      },
      //ajustements
      ajustement: {
        montant: 0,
        commentaire: "",
      },
      editFinanceFile: false,
      livrables: [],
      IMG_URL,
    };
  },
  validations: {
    newFdr: {
      endingAt: {
        required,
        minValue(val) {
          return this.newFdr.startedAt
            ? moment(val, "DD/MM/YYYY").diff(
                moment(this.newFdr.startedAt, "DD/MM/YYYY")
              ) +
                1 >
                0
            : true;
        },
      },
      startedAt: {
        required,
      },
      status: {
        required,
      },
      type: {
        required,
      },
      responsable: {
        required,
      },
      finance: {
        responsable: {
          required,
        },
      },
      sponsor: {
        required,
      },
      // patrimoine: {
      //   required,
      // },
      // organisation: {
      //   required,
      // },
      // entite: {
      //   required,
      // },
      budget: {
        minValue(val) {
          return parseFloat(val) > 0;
        },
        required,
      },
      name: {
        required,
        minLength: minLength(5),
      },
    },
    ajustement: {
      montant: {
        required,
        minValue(val) {
          if (this.FDR.budget >= 0) return val >= -this.FDR.budget;
          else true;
        },
      },
    },
  },
  methods: {
    datePickerFormat,
    deleteLivrable(livrable) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("financeFile/deleteFinanceFile", livrable.id)
            .then(() => {
              this.$store
                .dispatch("fdr/fetchFdr", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
              // });
            });
        }
      });
    },

    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    sendingFunction: function (location) {
      (!this.couvertures.find(
        (couverture) => couverture.countryCode === location.countryCode
      ) &&
        this.couvertures.push(this.clone(location))) ||
        Swal.fire(
          "Impossible d'ajouter une couverture déjà existante !",
          "",
          "error"
        );
    },
    deleteCouverture(id, index) {
      this.couvertures.splice(index, 1);
      id && this.deletedCouverture.push(id);
    },
    cancelEdit() {
      this.deletedCouverture = [];
      this.editMode = false;
      this.editing = "";
      this.$store.dispatch("fdr/fetchFdr", this.$route.params.id);
    },

    addStrategyChallengeInputs() {
      this.strategicChallenge.tableItems = [
        ...this.strategicChallenge.tableItems,
        {
          vision: null,
          strategic_goal: null,
          description: null,
          responsable: null,
          entity: null,
          organisation: null,
          editMode: true,
        },
      ];
    },
    removeStrategyChallengeInputs(index) {
      this.strategicChallenge.tableItems =
        this.strategicChallenge.tableItems.filter(
          (item, itemIndex) => itemIndex !== index
        );
    },
    updateFdr: function () {
      if (this.isManager || this.isAdmin || this.isSuper) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          delete this.newFdr.objectifs;
          delete this.newFdr.initiatives;
          delete this.newFdr.slug;
          delete this.newFdr.createdAt;
          delete this.newFdr.updatedAt;
          delete this.newFdr["@context"];
          delete this.newFdr["@id"];
          delete this.newFdr["@type"];
          delete this.newFdr.dossiers;

          this.$store
            .dispatch("financeFeuille/updateFinanceFeuille", {
              id: this.newFdr.finance.id,
              responsable: this.newFdr.finance.responsable,
            })
            .then(() => {
              delete this.newFdr.finance;

              this.$store
                .dispatch("fdr/updateFdr", {
                  ...this.newFdr,

                  budget: parseFloat(this.newFdr.budget),
                  startedAt: moment(this.newFdr.startedAt).format("DD/MM/YYYY"),
                  endingAt: moment(this.newFdr.endingAt).format("DD/MM/YYYY"),
                  couvertures: this.FDR.couvertures
                    .filter((couverture) => couverture["@id"])
                    .map((couverture) => couverture["@id"]),
                })
                .then(() => {
                  this.couvertures.forEach((element) => {
                    if (!element.id)
                      this.$store.dispatch("couverture/createCouverture", {
                        ...element,
                        feuilleRoute: this.FDR["@id"],
                      });
                  });
                  this.deletedCouverture.forEach((element) => {
                    if (element.id)
                      this.$store.dispatch(
                        "couverture/deleteCouverture",
                        element.id
                      );
                  });

                  this.$store
                    .dispatch("fdr/fetchFdr", this.$route.params.id)
                    .then(() => {
                      Swal.fire("La FdR est mise à jour !", "", "success");
                      this.editMode = false;
                      this.submitingForm = false;
                    });
                });
            });
        }
      }
    },

    addAjustement() {
      if (
        ((this.isPo || this.isManager) &&
          this.FDR.responsable.id === this.AuthenticatedUser.id) ||
        this.isAdmin ||
        this.isSuper
      ) {
        this.$v.$touch();
        if (!this.$v.ajustement.$invalid) {
          if (this.newFdr.finance) {
            // creating new ajustement
            this.$store
              .dispatch("ajustement/createAjustement", {
                ...this.ajustement,
                montant: this.ajustement.montant,
                finance: this.newFdr.finance["@id"],
                responsable: this.AuthenticatedUser["@id"],
              })
              .then(() => {
                this.$store
                  .dispatch(
                    "financeFeuille/fetchFinanceFeuille",
                    this.FDR.finance.id
                  )
                  .then(() => {
                    this.$store
                      .dispatch("fdr/fetchFdr", this.$route.params.id)
                      .then(() => {
                        Swal.fire({
                          title: "L'ajustement a été bien effectué !",
                          type: "success",
                          confirmButtonText: "OK",
                        });
                      });
                  });
              })
              .then(() => {
                this.newFdr.finance.ajustements.push({
                  ...this.ajustement,
                  createdAt: new Date(),
                });
                // this.$store.dispatch("fdr/fetchFdr", this.$route.params.id).then(() => {
                this.ajustement.montant = 0;
                this.ajustement.commentaire = "";
                // });
              });
          }
          if (!this.newFdr.finance)
            // if finance doesnt exist
            this.$store
              .dispatch("fdr/updateFdr", {
                // creating new finance
                id: this.$route.params.id,
                finance: {
                  responsable: this.FDR.responsable["@id"],
                  name: this.newFdr.finance
                    ? this.newFdr.name
                    : `finance de fdr ${this.newFdr.name}`,
                },
              })
              .then(() => {
                this.$store
                  .dispatch("fdr/fetchFdr", this.$route.params.id)
                  .then(() => {
                    // creating new ajustement
                    this.$store
                      .dispatch("ajustement/createAjustement", {
                        ...this.ajustement,
                        finance: this.FDR.finance["@id"],
                      })
                      .then(() => {
                        this.$store.dispatch(
                          "financeFeuille/fetchFinanceFeuille",
                          this.FDR.finance.id
                        );
                      });
                  });
              });
        }
      }
    },
    entityChanged() {
      this.newFdr.organisation = null;
    },
    confirmFinanceFile() {
      this.editFinanceFile = false;
      if (this.FDR.finance) {
        this.livrables.map((file) => {
          file.append("finance", this.FDR.finance["@id"]);
          this.$store
            .dispatch("financeFile/createFinanceFile", file)
            .then(() => {
              this.$store
                .dispatch("fdr/fetchFdr", this.$route.params.id)
                .then(() => {
                  Swal.fire(
                    "Le fichier a été téléversé avec succès !",
                    "",
                    "success"
                  );
                });
            });
          this.$store.dispatch("fdr/fetchFdr", this.$route.params.id);
        });
      } else {
        this.$store
          .dispatch("fdr/updateFdr", {
            id: this.$route.params.id,
            finance: {
              name: `FIN-FDR-${this.$route.params.id}`,
              responsable: this.FDR.responsable["@id"],
            },
          })
          .then(() => {
            this.$store
              .dispatch("fdr/fetchFdr", this.$route.params.id)
              .then(() => {
                this.livrables.map((file) => {
                  file.append("finance", this.FDR.finance["@id"]);
                  this.$store
                    .dispatch("financeFile/createFinanceFile", file)
                    .then(() => {
                      this.$store
                        .dispatch("fdr/updateFdr", {
                          id: this.$route.params.id,
                        })
                        .then(() => {
                          this.$store
                            .dispatch("fdr/fetchFdr", this.$route.params.id)
                            .then(() => {
                              Swal.fire(
                                "Le fichier a été téléversé avec succès !",
                                "",
                                "success"
                              );
                            });
                        });
                    });
                });
              });
          });
      }
      this.livrables = [];
    },
    filePickerChanger(files) {
      this.livrables = files;
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("cStatus/fetchAllStatusByType", "Feuille");
    this.$store.dispatch("cType/fetchFeuilleTypes");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("fdr/fetchFdr", this.$route.params.id).then(() => {
      this.$store.dispatch("loader/toggleLoading", false);
      this.FDR.finance &&
        this.$store.dispatch(
          "financeFeuille/fetchFinanceFeuille",
          this.FDR.finance.id
        );
    });
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("cStatus", ["STATUS_FEUILLES"]), //get all status
    ...mapGetters("cType", ["FEUILLE_TYPES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("fdr", ["FDR"]),
    ...mapGetters([
      "isAdmin",
      "isManager",
      "isSuper",
      "isPo",
      "isFinance",
      "AuthenticatedUser",
    ]),

    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusFdr() {
      return this.STATUS_FEUILLES;
    },
    organisations() {
      return this.newFdr.entite
        ? this.isAdmin || this.isSuper
          ? this.ENTITIES.find(
              (entite) => entite["@id"] === this.newFdr.entite
            ).organisations.map((organisation) => ({
              value: `/api/referentiel/organisations/${organisation.id}`,
              text: organisation.libelle,
            }))
          : this.AuthenticatedUser &&
            this.AuthenticatedUser.organisationRatachement
          ? [this.AuthenticatedUser.organisationRatachement].map(
              (organisation) => ({
                value: `/api/referentiel/organisations/${organisation.id}`,
                text: organisation.libelle,
              })
            )
          : []
        : [];
    },
    fdrTypes() {
      return this.FEUILLE_TYPES;
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine["@id"],
        text: patrimoine.name,
      }));
    },
    entityList() {
      return this.ENTITIES.filter(
        ({ organisations }) => organisations && organisations.length
      ).map((entite) => ({
        value: entite["@id"],
        text: entite.libelle,
      }));
    },
    objectifsList() {
      return this.FDR.objectifs;
    },

    ecartBudget() {
      return this.FDR.budget - this.initiativeBudget;
    },
    lastAdjustement() {
      return this.FDR.finance && this.FDR.finance.ajustements.length
        ? moment(
            this.FDR.finance.ajustements[
              this.FDR.finance.ajustements.length - 1
            ].createdAt
          ).format("DD/MM/YYYY")
        : "-";
    },
    initiativeFinance() {
      return this.FDR.finance;
    },
    initiativeBudget() {
      var initiativeBudget = 0;
      if (this.FDR.initiatives)
        this.FDR.initiatives.map((init) => {
          initiativeBudget += parseFloat(init.budget || 0);
        });
      return initiativeBudget;
    },
  },
  mounted() {
    if (this.$route.params.fromInitiative) {
      this.$store.dispatch("tabs/changeTab", 2);
      this.activeTab = 2;
    } else this.activeTab = this.$store.state.tabs.activeTab;
  },

  watch: {
    FDR() {
      this.newFdr = {
        ...this.FDR,
        entite:
          this.FDR.organisation && this.FDR.organisation.entite
            ? this.FDR.organisation.entite["@id"]
            : null,
        sponsor: this.FDR.finance.responsable
          ? this.FDR.finance.responsable["@id"]
          : null,
        organisation: this.FDR.organisation
          ? this.FDR.organisation["@id"]
          : null,
        patrimoine: this.FDR.patrimoine ? this.FDR.patrimoine["@id"] : null,
        responsable: this.FDR.responsable["@id"],
        startedAt: datePickerFormat(this.FDR.startedAt),
        endingAt: datePickerFormat(this.FDR.endingAt),
        finance: {
          ...this.FDR.finance,
          responsable: this.FDR.finance.responsable
            ? this.FDR.finance.responsable["@id"]
            : this.FDR.responsable["@id"],
        },
      };
      this.couvertures = this.FDR.couvertures;
      window.console.table(this.FDR);
    },
  },
};
</script>

<style scoped>
.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

@media (min-width: 991px) {
  .modal-xyz-548 {
    position: absolute;
    width: calc(100% - 331px) !important;
    height: 72% !important;
    top: 165px !important;
    right: 35px !important;
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
  }
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.sub-modal {
  background-color: white;
  padding: 20px 30px;
  width: 100%;
  height: 100%;
  position: relative;
}

.sb_mdl_ctrls {
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.mdl_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}

.spn {
  color: #797979;
}

.vs__selected-options,
.vs__dropdown-toggle,
.vs__selected {
  font-family: "ubuntu-regular", sans-serif !important;
  font-weight: normal !important;
}
</style>
