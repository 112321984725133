<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p class="h2">Fiche applicative</p>
        <hr />
      </div>
    </div>
    <b-form class="row">
      <div class="col-12 col-md-6">
        <div class="my-4">
          <span class="font-weight-bold mr-3">Libellé court:</span>
          <span>Libellé 1</span>
        </div>
        <div class="my-4">
          <span class="font-weight-bold mr-3">Statut:</span>
          <span>En prod</span>
        </div>
        <div class="my-4">
          <span class="font-weight-bold mr-3">Ligne métier:</span>
          <span>IARD</span>
        </div>
        <div class="my-4">
          <span class="font-weight-bold mr-3">Type SI:</span>
          <span>SI Interne</span>
        </div>
        <div class="my-4">
          <span class="font-weight-bold mr-3">Patrimoine:</span>
          <span>Lorem</span>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="my-4">
          <span class="font-weight-bold mr-3">Libellé long:</span>
          <span>Ipsum</span>
        </div>
        <div class="my-4">
          <span class="font-weight-bold mr-3">Type d'applications:</span>
          <span>Application métier</span>
        </div>
        <div class="my-4">
          <span class="font-weight-bold mr-3">Portée:</span>
          <span>Local</span>
        </div>
        <div class="my-4">
          <span class="font-weight-bold d-block mr-3 mb-2 w-100">Taux:</span>
          <b-progress max="100" show-progress height="15px">
            <b-progress-bar :value="80" :label="`${80}%`"></b-progress-bar>
          </b-progress>
        </div>
        <div class="my-4">
          <span class="font-weight-bold d-block mr-3 mb-2"> Couverture: </span>
          <div class="d-flex">
            <div class="d-flex shdow px-2 py-1 mr-1">
              <i class="flag-icon flag-icon-it mr-1"></i> Italie
            </div>
            <div class="d-flex shdow px-2 py-1 mr-1">
              <i class="flag-icon flag-icon-fr mr-1"></i> France
            </div>
            <div class="d-flex shdow px-2 py-1">
              <i class="flag-icon flag-icon-us mr-1"></i> Etats-Unis
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {};
</script>
<style></style>
